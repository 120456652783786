<template>
  <div>
    <working-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="siteContentObserver">
          <v-container fluid>
            <v-row class="d-none">
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.update_type.text"
                  :rules="items.update_type.rules"
                >
                  <v-select
                    ref="update_type"
                    v-model="site_content_config.update_type"
                    name="update_type"
                    :error-messages="errors"
                    :success="valid"
                    :items="update_type_items"
                    :no-data-text="items.update_type.text"
                    :label="items.update_type.text"
                    outlined
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="3">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.gidi_discount_time.text"
                  :rules="items.gidi_discount_time.rules"
                >
                  <v-text-field
                    ref="gidi_discount_time"
                    v-model="site_content_config.gidi_discount_time"
                    name="gidi_discount_time"
                    :error-messages="errors"
                    :success="valid"
                    :label="items.gidi_discount_time.text"
                    suffix="分"
                    outlined
                    type="number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" sm="3">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.gidi_discount_price.text"
                  :rules="items.gidi_discount_price.rules"
                >
                  <v-text-field
                    ref="gidi_discount_price"
                    v-model="site_content_config.gidi_discount_price"
                    name="gidi_discount_price"
                    :error-messages="errors"
                    :success="valid"
                    :label="items.gidi_discount_price.text"
                    suffix="円"
                    outlined
                    type="number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="3">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.gidi_dlt.text"
                  :rules="items.gidi_dlt.rules"
                >
                  <v-select
                    ref="gidi_dlt"
                    v-model="site_content_config.gidi_dlt"
                    name="gidi_dlt"
                    :error-messages="errors"
                    :success="valid"
                    :items="gidi_dlt_items"
                    :no-data-text="items.gidi_dlt.text"
                    :label="items.gidi_dlt.text"
                    outlined
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.gidi_info_text.text"
                  :rules="items.gidi_info_text.rules"
                >
                  <v-textarea
                    ref="gidi_info_text"
                    v-model="site_content_config.gidi_info_text"
                    name="gidi_info_text"
                    :error-messages="errors"
                    :success="valid"
                    :label="items.gidi_info_text.text"
                    rows="4"
                    outlined
                    hint="※未設定の場合、媒体に表示される初期値が適用されます。"
                    persistent-hint
                    background-color="white"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  class="mt-0"
                  v-model="site_content_config.gidi_list_flag"
                  mandatory
                  row
                >
                  <v-radio label="新着に掲載する" value="1"></v-radio>
                  <v-radio label="しない" value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </working-site-card>
  </div>
</template>

<script>
import { set, reactive, toRefs, defineComponent } from "@vue/composition-api";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const state = reactive({
      site_content_config: {},
      items: [],
    });

    const update_type_items = [
      { value: 1, text: "通常更新：待機中セラピストを一括で更新" },
      {
        value: 2,
        text: "高頻度更新：1分間隔で待機中セラピスト2名をランダムに更新",
      },
    ];

    const gidi_dlt_items = [
      { value: 10, text: "10分後" },
      { value: 15, text: "15分後" },
      { value: 30, text: "30分後" },
      { value: 45, text: "45分後" },
      { value: 60, text: "60分後" },
      { value: 90, text: "90分後" },
      { value: 120, text: "120分後" },
    ];

    const init = async () => {
      // 個別項目の設定を取得
      state.items = setting.working_status_config.find(
        (item) => item.site_content_id === props.com_data.site_content_id
      ).items;

      // state.site_content_configにキーを設定
      for (let key in state.items) {
        set(state.site_content_config, key, null);
      }
      if (props.com_data.site_content_config) {
        Object.keys(props.com_data.site_content_config).forEach(function (key) {
          set(
            state.site_content_config,
            key,
            props.com_data.site_content_config[key]
          );
        });
      }
      // 初期値を設定する
      if (state.site_content_config["update_type"] == null) {
        set(state.site_content_config, "update_type", 1);
      }
      if (state.site_content_config["gidi_list_flag"] == null) {
        set(state.site_content_config, "gidi_list_flag", 1);
      }
      if (state.site_content_config["gidi_dlt"] == null) {
        set(state.site_content_config, "gidi_dlt", 60);
      }
      // console.log(state.site_content_config);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      return await ctx.refs.siteContentObserver.validate();
    };

    const getConfig = async () => {
      return state.site_content_config;
    };

    return {
      props,
      setting,
      update_type_items,
      gidi_dlt_items,
      ...toRefs(state),
      checkValid,
      getConfig,
    };
  },
});
</script>

<style scoped></style>
